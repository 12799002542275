// FADE TO TOP
var $animation_elements = $('.animated');
var $window = $(window);

function check_if_in_view() {
    var window_height = $window.height();
    var window_top_position = $window.scrollTop();
    var window_bottom_position = (window_top_position + window_height);

    $.each($animation_elements, function() {
        var $element = $(this);
        var element_height = $element.outerHeight();
        var element_top_position = $element.offset().top;
        var element_bottom_position = (element_top_position + element_height);

        //check to see if this current container is within viewport
        if ((element_bottom_position >= window_top_position) &&
            (element_top_position <= window_bottom_position)) {
            $element.addClass('active');
        }
        //else {
        //$element.removeClass('active');
        //}
    });
}

$window.on('scroll resize', check_if_in_view);
$window.trigger('scroll');

// IMAGE REPLACE - img src to bg css
function replaceSrc() {

    $('.js-replace-img').each(function() {

        var src = $(this).find("img").attr("src");
        $(this).css("background-image", "url(" + src + ")");
    });
}

jQuery(function() {
    if ($(".js-replace-img")) {
        replaceSrc();
    }
});


(function() {

    var s = {

        menuState: function() {

            $(this).toggleClass("cross");
            $(".main-nav-wrapper").toggleClass("open");

        },

        headerHeight: function() {

            if ($(window).scrollTop() > 0) {

                return $('.main-header').height();
            } else {

                return $('.main-header').height() - 24;
            }
        },

        headerPosition: function() {

            var top = $(window).scrollTop();

            if (top > 300) {

                $(".main-header").addClass("min");
                $(".sub-nav").addClass("min");
            } else {

                $(".main-header").removeClass("min");
                $(".sub-nav").removeClass("min");
            }
        },

        events: function() {
            $(window).scroll(this.headerPosition);
            $("#btnMobile").on("click", this.menuState);
        },

        init: function() {

            this.headerPosition();
            this.events();
        }

    };

    s.init();

})();

// SUBNAV NAVIGATION
$("ul > li.menu-item-has-children > a").click(function (e) {
    e.preventDefault();
    var LiParent =$(this).parent().attr('class');
    if(! LiParent.includes("openSub-menu") ){
        $('ul > li.menu-item-has-children > a').parent().removeClass("openSub-menu");
    }

    $(this).parent().toggleClass("openSub-menu");

});

// -- CLOSE NAV WHEN CLICK ANYWHERE
$(document).on("click", function (event) {
    var $trigger = $(".menu-item-has-children");
    if ($trigger !== event.target && !$trigger.has(event.target).length) {
        $("ul.sub-menu").removeClass("openSub-menu");
    }
});

// ANCRE
$('a[href^="#"]').on('click',function (e) {
    e.preventDefault();

    var nav = $('nav');
    var target = this.hash,
    $target = $(target);

    $('html, body').stop().animate({
        'scrollTop': $target.offset().top - nav.height()
    }, 1200, 'swing', function () {
        window.location.hash = target;
    });
});

// SLIDESHOW
$(document).ready(function(){
  $('.slideshow .slider-wrapper').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    infinite: true,
    cssEase: 'ease',
    fade: true,
    speed: 1000,
    dots: false,
  });
});

// SLIDER BOTTOM PAGE
var totalSlides = $('.slider-items article').length;
var sliderSettings = {
    slidesToShow: totalSlides >= 3 ? 3 : totalSlides,
    slidesToScroll: 1,
    infinite: true,
    dots: false,
    responsive: [
        {
            breakpoint: 1500,
            settings: {
              slidesToShow: totalSlides >= 2 ? 2 : totalSlides,
            }
        },
        {
          breakpoint: 769,
          settings: {
            slidesToShow: totalSlides >= 1 ? 1 : totalSlides,
          }
      },
    ],
  };

// HOME AGENDA SLIDE
$(document).ready(function(){
    var totalSlidesAgenda = $('.agenda-slide article').length;
    $('.agenda-slide').slick(
      {

        slidesToShow: totalSlides >= 3 ? 3 : totalSlidesAgenda,
        slidesToScroll: 1,
        infinite: true,
        dots: false,
        responsive: [
            {
                breakpoint: 1500,
                settings: {
                  slidesToShow: totalSlides >= 2 ? 2 : totalSlidesAgenda,
                }
            },
            {
              breakpoint: 769,
              settings: {
                slidesToShow: totalSlides >= 1 ? 1 : totalSlidesAgenda,
              }
          },
        ],

      }

    );
});

// SERVICES SLIDE
$(document).ready(function(){
    $('.slider-items').slick(sliderSettings);
});
